import CSSecondaryButton, { CSSecondaryButtonProps } from "components/shared/button/CSSecondaryButton";
import React, { ReactNode } from "react";

import classNames from "classnames";

type CSCancelButtonProps = Omit<CSSecondaryButtonProps, "children" | "buttonText"> & {
  buttonText?: ReactNode;
  isSubmitting?: boolean;
};

/**
 * Renders a button that is used for a cancel button within a form.
 * See https://react-bootstrap.github.io/components/buttons/#api for props.
 */
const CSCancelButton = ({ className, buttonText = "Cancel", ...buttonProps }: CSCancelButtonProps) => {
  return (
    <CSSecondaryButton {...buttonProps} className={classNames("cs-cancel-btn", className)} buttonText={buttonText} />
  );
};

export default CSCancelButton;
