import { useAuthState } from "context/auth";
import { useMultiScoreState } from "context/MultiScoreContext";
import { useMultiTimeviewState } from "context/multi-timeview";
import { useQuestionState } from "context/question";
/**
 * Maps each permission in the list to `true` or `false` to indicate
 * if the provided user has that permission.
 * @param {string[]} toCheck array of permissions to check
 * @param {string[]} permissions array of permissions to check _against_.
 * @returns {Boolean[]} array of booleans indicating which permissions were present in the provided list.
 */
export const checkUserPermissions = (toCheck, permissions) => {
  return toCheck.map((x) => permissions.includes(x));
};

/**
 * Wraps `checkUserPermissions` to check against the current user's static permissions
 * You can use this in a component to hide/show features based on the current user's permissions.
 *
 * @example
 * const [canViewTargets, canViewFeeds] = useCheckUserPermissions(['target:view', 'question-feed:view']);
 *
 * // ...later something like...
 *
 * { canViewTargets && <TargetsLink /> }
 * { canViewFeeds && <QuestionFeedsLink /> }
 *
 * @param {string[]} toCheck array of permissions to check.
 * @returns {Boolean[]} array of booleans indicating which permissions the current user has been granted.
 */
export const useCheckUserPermissions = (toCheck) => {
  const { currentUser } = useAuthState();
  return checkUserPermissions(toCheck, currentUser.permissions);
};

/**
 *
 * Wraps `checkUserPermissions` to check against the current user's question permissions
 * You can use this in a component to hide/show features based on the current user's question permissions.
 *
 * @param {string[]} toCheck array of permissions to check.
 * @returns {Boolean[]} array of booleans indicating which permissions the current user has been granted.
 */
export const useCheckQuestionPermissions = (toCheck) => {
  const { currentQuestion } = useQuestionState();
  return checkUserPermissions(toCheck, currentQuestion.permissions);
};

/**
 *
 * Wraps `checkUserPermissions` to check against the current user's multi score permissions
 * You can use this in a component to hide/show features based on the current user's multi score permissions.
 *
 * @param {string[]} toCheck array of permissions to check.
 * @returns {Boolean[]} array of booleans indicating which permissions the current user has been granted.
 */
export const useCheckMultiScorePermissions = (toCheck) => {
  const { currentMultiScore } = useMultiScoreState();
  return checkUserPermissions(toCheck, currentMultiScore.permissions);
};

/**
 *
 * Wraps `checkUserPermissions` to check against the current user's multi timeview permissions
 * You can use this in a component to hide/show features based on the current user's multi timeview permissions.
 *
 * @param {string[]} toCheck array of permissions to check.
 * @returns {Boolean[]} array of booleans indicating which permissions the current user has been granted.
 */
export const useCheckMultiTimeviewPermissions = (toCheck) => {
  const { currentMultiTimeview } = useMultiTimeviewState();
  return checkUserPermissions(toCheck, currentMultiTimeview.permissions);
};
