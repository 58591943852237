import React, { ReactNode, createContext, useContext } from "react";
import { formatDateFilter, querystring as qs, useQueryStringNav } from "utils";

import { mapReportApiDatesToFormDates } from "utils/dates/dateFilters";
import { noOp } from "@civicscience/chops";
import { useLocation } from "react-router-dom";
import { useMultiTimeviewState } from "context/multi-timeview/MultiTimeviewProvider";

/*
FIXME: Reintroduce custom and relative date filters when
requirements are defined around these with the in-line filters pattern
*/

// export type MultiTimeviewFilterProps = ReportResultsDateFilter;
export type MultiTimeviewFilterProps = {
  date: string | null;
};
type MultiTimeviewFiltersContextProps = {
  // dateFilter: ReportDateFilterType | null;
  // customFixedDateFilter: CustomFixedDateFormValues;
  // customRelativeDateFilter: CustomRelativeDateFormValues;
  date: string | null;
  setAllFilters: ({ date }: MultiTimeviewFilterProps) => void;
  resetFilters: () => void;
};

// export const EmptyCustomFixedDateFilter = {
//   minDate: null,
//   maxDate: null,
// };
// export const EmptyCustomRelativeDateFilter = {
//   periodValue: null,
//   periodUnit: null,
//   relativeValue: null,
//   relativeUnit: null,
// };

const emptyContext = {
  // dateFilter: null,
  // customFixedDateFilter: EmptyCustomFixedDateFilter,
  // customRelativeDateFilter: EmptyCustomRelativeDateFilter,
  date: null,
  setAllFilters: noOp,
  resetFilters: noOp,
};

export const MultiTimeviewFiltersContext = createContext<MultiTimeviewFiltersContextProps>(emptyContext);
MultiTimeviewFiltersContext.displayName = "MultiTimeviewFiltersContext";

export const DefaultMultiTimeviewFilters: MultiTimeviewFilterProps = {
  // dateFilter: 'all',
  // customFixedDateFilter: EmptyCustomFixedDateFilter,
  // customRelativeDateFilter: EmptyCustomRelativeDateFilter,
  date: "all",
};

export const DefaultMultiTimeviewFilterIds = {
  dateFilter: "all",
  endDate: null,
  startDate: null,
};

// FIXME: https://civicscience.atlassian.net/browse/SOF-3411
const dayLabelToFilterValues = {
  all: "all",
  "relative-days-7": "7",
  "relative-days-30": "30",
  "relative-days-90": "90",
  "relative-days-180": "180",
  "relative-days-365": "365",
  "custom-fixed": "all",
  "custom-relative": "all",
};

type MultiTimeviewFiltersProviderProps = {
  children: ReactNode;
};

/**
 * This context separately holds multi timeview filters in both object and id form.
 * It also manages reading/writing the applied filter IDs to the querystring.
 *
 */
export const MultiTimeviewFiltersProvider = ({ children }: MultiTimeviewFiltersProviderProps) => {
  const { currentMultiTimeview } = useMultiTimeviewState();
  const { pathname } = useLocation();
  const defaultDateFilter = mapReportApiDatesToFormDates(currentMultiTimeview?.jsonFilters?.dateRange);

  const { queryParams, navigate } = useQueryStringNav(pathname, {
    // dateFilter: qs.stringParserWithDefault('all'),
    // minDate: qs.dateParserWithDefault(null),
    // maxDate: qs.dateParserWithDefault(null),
    // periodValue: qs.stringParserWithDefault(null),
    // periodUnit: qs.stringParserWithDefault(null),
    // relativeValue: qs.stringParserWithDefault(null),
    // relativeUnit: qs.stringParserWithDefault(null),
    date: qs.stringParserWithDefault(dayLabelToFilterValues[defaultDateFilter.dateFilter]),
  });

  // const customFixedDateFilter = {
  //   minDate: queryParams.minDate,
  //   maxDate: queryParams.maxDate,
  // };

  // const customRelativeDateFilter = {
  //   periodValue: queryParams.periodValue,
  //   periodUnit: queryParams.periodUnit,
  //   relativeValue: queryParams.relativeValue,
  //   relativeUnit: queryParams.relativeUnit
  // };
  /**
   * This method allows the user to set all state filters.
   */
  const setAllFilters = ({ date }: MultiTimeviewFilterProps) => {
    navigate({
      date: date,
      // dateFilter: dateFilter,
      // minDate: customFixedDateFilter.minDate,
      // maxDate: customFixedDateFilter.maxDate,
      // periodValue: customRelativeDateFilter.periodValue,
      // periodUnit: customRelativeDateFilter.periodUnit,
      // relativeValue: customRelativeDateFilter.relativeValue,
      // relativeUnit: customRelativeDateFilter.relativeUnit,
    });
  };

  /**
   * Reset all state filters back to default
   */
  const resetFilters = () => {
    navigate({ date: "all" });
  };

  return (
    <MultiTimeviewFiltersContext.Provider
      value={{
        date: queryParams.date,
        // dateFilter: queryParams.dateFilter,
        // customFixedDateFilter,
        // customRelativeDateFilter,
        setAllFilters,
        resetFilters,
      }}
    >
      {children}
    </MultiTimeviewFiltersContext.Provider>
  );
};

export const useMultiTimeviewFiltersState = () => {
  const {
    // dateFilter,
    // customFixedDateFilter,
    // customRelativeDateFilter,
    date,
    setAllFilters,
    resetFilters,
  } = useContext(MultiTimeviewFiltersContext);

  // const filterDateIds = reportResultsDateFilterToDateRange(
  //   { dateFilter, customFixedDateFilter, customRelativeDateFilter }
  // );

  return {
    filterObjects: {
      // dateFilter,
      // customFixedDateFilter,
      // customRelativeDateFilter,
      date,
    },
    filterIds: {
      ...formatDateFilter(date),
      // dateFilter,
      // ...filterDateIds
    },
    setAllFilters,
    resetFilters,
  };
};
