import "components/shared/navigation/AppLeftNavbar.scss";

import { Dropdown, DropdownProps, Nav, NavLinkProps, Navbar, NavbarProps } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import React, { useRef } from "react";
import { defaultRoute, routePaths } from "routing";
import { isEnvLocalOrQA, useAdaptiveHelpers, useCheckUserPermissions } from "utils";
import { noOp, scrollToNode } from "@civicscience/chops";

import CSButton from "components/shared/button/CSButton";
import CSDropdownMenu from "components/shared/dropdown/CSDropdownMenu";
import CSIcon from "components/shared/icon/CSIcon";
import CSLink from "components/shared/links/CSLink";
import { Help } from "consts";
import Logo from "components/shared/logo/Logo";
import UserPermissionTypes from "consts/UserPermissionTypes";
import classNames from "classnames";
import { envConfig } from "config";
import { useAuthState } from "context/auth";

const { audienceLensUrl, sageUrl } = envConfig;

type LeftNavSectionTitleProps = {
  children: React.ReactNode;
  className?: string;
};

const LeftNavSectionTitle = ({ children, className }: LeftNavSectionTitleProps): JSX.Element => (
  <div className={classNames("csa-app-left-navbar-nav-section-title", className)}>{children}</div>
);

type LeftNavBaseLinkProps = {
  to: string;
  title: string;
  onNavigate?: undefined | (() => void);
  children: React.ReactNode;
};

type LeftNavBaseExternalLinkProps = Omit<LeftNavBaseLinkProps, "to"> & {
  href: string;
};

type LeftNavItemProps = NavLinkProps &
  LeftNavBaseLinkProps & {
    children: React.ReactNode;
    as?: React.ElementType;
    iconClass?: string;
    iconOnly?: boolean;
    disabled?: boolean;
  };

type LeftNavItemExternalLinkProps = NavLinkProps &
  LeftNavBaseExternalLinkProps & {
    children: React.ReactNode;
    as?: React.ElementType;
    iconClass?: string;
    iconOnly?: boolean;
  };

/**
 * NOTE: a uniquely named eventKey is a hack needed the Navbar to collapseOnSelect when a NavLink is used.
 * https://stackoverflow.com/questions/54859515/react-bootstrap-navbar-collapse-not-working/56485081#56485081
 */
const LeftNavItem = ({
  to,
  title,
  children,
  iconClass,
  iconOnly = false,
  disabled = false,
  onNavigate = undefined,
  className,
  ...navLinkProps
}: LeftNavItemProps): JSX.Element => (
  <Nav.Link
    {...navLinkProps}
    eventKey={disabled ? "disabled" : to}
    as={disabled ? undefined : NavLink}
    to={to}
    className={classNames(
      "",
      { "disabled text-muted allow-pointer-events": disabled, "text-light": !disabled },
      className,
    )}
    title={iconOnly ? title : undefined}
    aria-label={title}
    onClick={disabled ? undefined : onNavigate}
    disabled={disabled}
  >
    <CSIcon icon="fa-light" className={iconClass} />
    <span className="d-inline-block d-sm-none me-auto">{children}</span>
    {!iconOnly && <span className="d-none d-sm-inline-block me-sm-auto">{children}</span>}
  </Nav.Link>
);

const LeftNavItemExternalLink = ({
  title,
  children,
  iconClass = "fa-arrow-up-right-from-square",
  iconOnly = false,
  onNavigate = undefined,
  className,
  href,
  ...navLinkProps
}: LeftNavItemExternalLinkProps): JSX.Element => (
  <Nav.Link
    {...navLinkProps}
    href={href}
    target="_blank"
    rel="noreferrer"
    className={classNames("text-light", className)}
    title={iconOnly ? title : undefined}
    aria-label={title}
    onClick={onNavigate}
  >
    <CSIcon icon="fa-light" className={iconClass} />
    <span className="d-inline-block d-sm-none me-auto">{children}</span>
    {!iconOnly && <span className="d-none d-sm-inline-block me-sm-auto">{children}</span>}
  </Nav.Link>
);

type LeftNavMenuProps = DropdownProps & {
  children: React.ReactNode;
  text: string;
  title?: string;
  iconClass: string;
  iconOnly?: boolean;
  openMenuEnd?: boolean;
};

const LeftNavMenu = ({
  children,
  text,
  title,
  iconClass,
  iconOnly = false,
  openMenuEnd = false,
  ...dropdownProps
}: LeftNavMenuProps): JSX.Element => (
  <Dropdown
    {...dropdownProps}
    drop={openMenuEnd ? "end" : "up"}
    className="csa-app-left-nav-dropdown text-center text-md-start"
    align="start"
  >
    <Dropdown.Toggle
      variant="transparent"
      className="rounded-0 border-0 text-light"
      aria-label={title || text}
      title={title || text}
    >
      <CSIcon icon="fa-light" className={iconClass} />
      <span className="d-inline-block d-sm-none me-auto">{text}</span>
      {!iconOnly && <span className="d-none d-sm-inline-block me-sm-auto">{text}</span>}
    </Dropdown.Toggle>
    <CSDropdownMenu align="start" className="border-gray-600 bg-gray-700" rounded={false}>
      {children}
    </CSDropdownMenu>
  </Dropdown>
);

type LeftNavMenuItemProps = LeftNavBaseLinkProps;

const LeftNavMenuItem = ({
  to,
  children,
  title,
  onNavigate = undefined,
  ...navItemProps
}: LeftNavMenuItemProps): JSX.Element => (
  <Dropdown.Item {...navItemProps} eventKey={to} as={NavLink} to={to} title={title} onClick={onNavigate}>
    {children}
  </Dropdown.Item>
);

type AppLeftNavbarProps = NavbarProps & {
  id?: string;
  children?: React.ReactNode;
  navExpanded?: boolean;
  onToggle: () => void;
  onNavigate?: () => void;
};

const AppLeftNavbar = ({
  id,
  navExpanded = false,
  onToggle = noOp,
  onNavigate = noOp,
  ...navbarProps
}: AppLeftNavbarProps): JSX.Element => {
  const { currentAccount } = useAuthState();
  const location = useLocation();
  const { isMobile } = useAdaptiveHelpers();

  const appNavLeftStartRef = useRef<HTMLDivElement>(null);
  const appNavLeftEndRef = useRef<HTMLDivElement>(null);
  const [canViewTargets, canViewFeeds, canViewAdminMenu] = useCheckUserPermissions([
    UserPermissionTypes.TARGET_LIST,
    UserPermissionTypes.QUESTION_FEED_LIST,
    UserPermissionTypes.SYSADMIN,
  ]);
  const showDefineSection = [canViewTargets, canViewFeeds].some((x) => x);
  const showAudienceLensLink = (currentAccount?.isInternal || currentAccount?.isPublisher) ?? false;
  const homeLinkTitle = "Home Page (Home)";
  const toggleButtonTitle = navExpanded ? "Collapse Menu" : "Expand Menu";
  const openSubMenuEnd = !navExpanded || isMobile;

  const {
    DASHBOARD_DEFAULT,
    QUESTIONS,
    TARGETS,
    SEGMENTS,
    QUESTION_FEEDS,
    MATRIX_QUESTIONS,
    TOOLKIT,
    LOGOUT,
    WEIGHTING_SCHEMES,
    DEPLOYMENT_SCHEMES,
    REPORTS,
    CROSSTABS,
    PROFILE,
    CHECKBOX_GROUPS,
    TAGS,
    SYSADMIN,
    NUGGET_PRESET_TRAIT_BUSINESS,
    STAR_RATING_QUESTIONS,
  } = routePaths;
  const skipNavigation = (scrolling = false) => {
    const appNavEndNode = appNavLeftEndRef && appNavLeftEndRef.current;
    if (appNavEndNode) {
      scrolling && scrollToNode(appNavEndNode);
      appNavEndNode.focus();
    }
  };

  const showToolkit = isEnvLocalOrQA();

  const onToggleWrap = () => {
    appNavLeftStartRef?.current?.focus();

    onToggle();
  };

  return (
    <>
      <Navbar
        {...navbarProps}
        className={classNames("csa-app-left-navbar text-light flex-column", {
          "nav-expanded": navExpanded,
          "nav-collapsed": !navExpanded,
        })}
        bg="dark"
        variant="dark"
        onSelect={() => skipNavigation(false)}
        role="navigation"
        id={id}
      >
        <div
          className="csa-app-left-nav-start visually-hidden"
          ref={appNavLeftStartRef}
          tabIndex={-1}
          role="note"
          aria-label={`You have reached the beginning of the ${navExpanded ? "expanded" : "collapsed"} left nav.`}
        />
        <CSButton.Link
          className="csa-skip-nav visually-hidden"
          onClick={() => skipNavigation(true)}
          buttonText="Skip Navigation"
        />

        <div className="csa-app-left-nav-toggle-wrap">
          <button
            className="csa-app-left-nav-toggle"
            type="button"
            aria-controls={id}
            aria-haspopup="true"
            aria-expanded={navExpanded}
            title={toggleButtonTitle}
            onClick={onToggleWrap}
          >
            <CSIcon
              icon="fa-light"
              className={classNames("d-none d-sm-block", {
                "fa-angle-left": navExpanded,
                "fa-angle-right": !navExpanded,
              })}
            />
            <CSIcon icon="fa-light fa-bars" className="d-inline-block d-sm-none" />
            <span className="csa-i-text visually-hidden">Toggle Navigation</span>
          </button>
          <CSLink to={defaultRoute.path} className="csa-app-left-nav-toggle-home-link" title={homeLinkTitle}>
            <Logo className="align-text-top" />
          </CSLink>
        </div>

        <Navbar.Brand as={NavLink} to={defaultRoute.path} className="csa-app-left-navbar-brand" title={homeLinkTitle}>
          <Logo className="d-inline-block align-text-top" />
          {navExpanded && (
            <div className={classNames("csa-app-left-brand-title ps-3 d-none", { "d-sm-inline-block": navExpanded })}>
              <strong>Insight</strong>Store
            </div>
          )}
        </Navbar.Brand>

        <Nav className="csa-app-left-navbar-nav flex-column" activeKey={location.pathname}>
          <div className="csa-app-left-nav-top">
            <div className="csa-app-left-navbar-nav-section">
              <LeftNavSectionTitle>ANALYZE</LeftNavSectionTitle>
              <LeftNavItem
                to={DASHBOARD_DEFAULT}
                iconClass="fa-table-columns"
                iconOnly={!navExpanded}
                title="Dashboard"
                onNavigate={onNavigate}
              >
                Dashboard
              </LeftNavItem>
              <LeftNavItem
                to={REPORTS}
                iconClass="fa-file-chart-column"
                iconOnly={!navExpanded}
                title="Reports"
                onNavigate={onNavigate}
              >
                Reports
              </LeftNavItem>
              <LeftNavItem
                to={CROSSTABS}
                iconClass="fa-grid-dividers"
                iconOnly={!navExpanded}
                title="Crosstabs"
                onNavigate={onNavigate}
              >
                Crosstabs
              </LeftNavItem>
            </div>
            <div className="csa-app-left-navbar-nav-section">
              <LeftNavSectionTitle>EXPLORE</LeftNavSectionTitle>
              <LeftNavItem
                to={QUESTIONS}
                iconClass="fa-block-question"
                iconOnly={!navExpanded}
                title="Questions"
                onNavigate={onNavigate}
              >
                Questions
              </LeftNavItem>
              <LeftNavItem
                to={SEGMENTS}
                iconClass="fa-users-between-lines"
                iconOnly={!navExpanded}
                title="Segments"
                onNavigate={onNavigate}
              >
                Segments
              </LeftNavItem>
            </div>

            {showDefineSection && (
              <div className="csa-app-left-navbar-nav-section">
                <LeftNavSectionTitle>DEFINE</LeftNavSectionTitle>
                {canViewTargets && (
                  <LeftNavItem
                    to={TARGETS}
                    iconClass="fa-bullseye-arrow"
                    iconOnly={!navExpanded}
                    title="Targets"
                    onNavigate={onNavigate}
                  >
                    Targets
                  </LeftNavItem>
                )}
                {canViewFeeds && (
                  <LeftNavItem
                    to={QUESTION_FEEDS}
                    iconClass="fa-tower-broadcast"
                    iconOnly={!navExpanded}
                    title="Feeds"
                    onNavigate={onNavigate}
                  >
                    Feeds
                  </LeftNavItem>
                )}
              </div>
            )}

            <div className="csa-app-left-navbar-nav-section">
              <LeftNavSectionTitle>LABS</LeftNavSectionTitle>
              {showAudienceLensLink && (
                <LeftNavItemExternalLink
                  href={audienceLensUrl}
                  iconClass="fa-magnifying-glass-plus"
                  iconOnly={!navExpanded}
                  title="AudienceLens"
                >
                  AudienceLens
                </LeftNavItemExternalLink>
              )}
              <LeftNavItemExternalLink href={sageUrl} iconClass="fa-comment-dots" iconOnly={!navExpanded} title="Sage">
                Sage
              </LeftNavItemExternalLink>
            </div>
          </div>

          <div className="csa-app-left-nav-bottom">
            <div className="csa-app-left-navbar-nav-section">
              <LeftNavSectionTitle className="mt-4">ADVANCED</LeftNavSectionTitle>
              {canViewAdminMenu && (
                <LeftNavMenu
                  text="Admin"
                  title="Administrative"
                  iconClass="fa-user-shield"
                  iconOnly={!navExpanded}
                  openMenuEnd={openSubMenuEnd}
                >
                  <LeftNavMenuItem to={WEIGHTING_SCHEMES} title="Weighting Schemes" onNavigate={onNavigate}>
                    Weighting Schemes
                  </LeftNavMenuItem>
                  <LeftNavMenuItem to={DEPLOYMENT_SCHEMES} title="Deployment Schemes" onNavigate={onNavigate}>
                    Deployment Schemes
                  </LeftNavMenuItem>
                  <LeftNavMenuItem to={MATRIX_QUESTIONS} title="Matrix Questions" onNavigate={onNavigate}>
                    Matrix Questions
                  </LeftNavMenuItem>
                  <LeftNavMenuItem to={CHECKBOX_GROUPS} title="Checkbox Groups" onNavigate={onNavigate}>
                    Checkbox Groups
                  </LeftNavMenuItem>
                  <LeftNavMenuItem to={TAGS} title="Tags">
                    Tags
                  </LeftNavMenuItem>
                  <LeftNavMenuItem to={STAR_RATING_QUESTIONS} title="Star Rating Questions" onNavigate={onNavigate}>
                    Star Rating Questions
                  </LeftNavMenuItem>
                  <LeftNavMenuItem to={SYSADMIN} title="SysAdmin" onNavigate={onNavigate}>
                    SysAdmin
                  </LeftNavMenuItem>
                  <LeftNavMenuItem
                    to={NUGGET_PRESET_TRAIT_BUSINESS}
                    title="Nugget Preset Traits"
                    onNavigate={onNavigate}
                  >
                    Nugget Preset Traits
                  </LeftNavMenuItem>
                  {showToolkit && (
                    <LeftNavMenuItem to={TOOLKIT} title="Toolkit" onNavigate={onNavigate}>
                      Toolkit
                    </LeftNavMenuItem>
                  )}
                </LeftNavMenu>
              )}
              <LeftNavItem
                to={PROFILE}
                iconClass="fa-id-card"
                iconOnly={!navExpanded}
                title="Profile"
                onNavigate={onNavigate}
              >
                Profile
              </LeftNavItem>
              <LeftNavMenu
                text="Help"
                title="Help & Support"
                iconClass="fa-life-ring"
                iconOnly={!navExpanded}
                openMenuEnd={openSubMenuEnd}
              >
                <Dropdown.Item
                  eventKey={Help.HelpUrl}
                  href={Help.HelpUrl}
                  target="_blank"
                  rel="noreferrer"
                  onClick={onNavigate}
                >
                  Help Center
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey={"REPORT_BUG"}
                  href={Help.ContactUsUrl}
                  target="_blank"
                  rel="noreferrer"
                  onClick={onNavigate}
                >
                  Report a Bug
                </Dropdown.Item>
              </LeftNavMenu>
              <LeftNavItem
                to={LOGOUT}
                iconClass="fa-arrow-right-from-bracket"
                iconOnly={!navExpanded}
                title="Logout"
                onClick={onNavigate}
              >
                Logout
              </LeftNavItem>
            </div>
          </div>
        </Nav>
      </Navbar>
      <div
        className="csa-app-left-nav-end visually-hidden"
        ref={appNavLeftEndRef}
        tabIndex={-1}
        role="note"
        aria-label="You have reached the end of the left nav."
      />
    </>
  );
};

export default AppLeftNavbar;
