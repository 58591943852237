import { QuestionFilter, QuestionFilterDisplayOption, QuestionFilters } from "types";
import { isNullOrUndef, isNullOrUndefOrEmpty } from "@civicscience/chops";

import { ValueParser } from "utils/url/querystring";
import { parseNumberOrDefault } from "utils/numbers";

type QuestionFilterMatch<Id extends string | number, TResult> = {
  applied: (id: Id) => TResult;
  "not-applied": () => TResult;
  "clear-current-value": () => TResult;
};

type MatchDisplayOptionMap<T> = {
  grouping: (uri: string) => T;
  score: (uri: string) => T;
  ungrouped: () => T;
};

export const QUESTION_FILTER_ALL = "all";
export const QUESTION_FILTER_NONE = "none";

/**
 * Helper to parse `val` to a question filter.
 * Handles 3 general cases with the last case allowing you a callback to map the value:
 *
 * `QUESTION_FILTER_NONE`, `null`, `undefined`  => { kind: 'not-applied' }
 * `QUESTION_FILTER_ALL`                        => { kind: 'clear-current-value' }
 * any other string (including empty string)    => { kind: 'applied', value: val }
 *
 * Note that empty string is considered an applied filter.
 * This is especially important for networks and weighting schemes which include
 * options from the API with empty string as their value.
 */
const parseQuestionFilter = <T extends string | number>(
  val: string | null | undefined,
  mapFn: (x: string) => T = (x) => x as T,
  emptyNotApplied = false,
): QuestionFilter<T> => {
  //NOTE: Sometimes (for Weighting Scheme) an empty string is needed to be applied as a value for the filter,
  // other times empty does not need to be applied
  const emptyCheckFn = emptyNotApplied ? isNullOrUndefOrEmpty : isNullOrUndef;
  return emptyCheckFn(val) || val === QUESTION_FILTER_NONE
    ? { kind: "not-applied" }
    : val === QUESTION_FILTER_ALL
      ? { kind: "clear-current-value" }
      : { kind: "applied", value: mapFn(val) };
};

export const segmentParser = (fallback: QuestionFilter<string>): ValueParser<QuestionFilter<string>> => ({
  defaultValue: fallback,
  parser: parseQuestionFilter,
});

export const targetParser = (fallback: QuestionFilter<number>): ValueParser<QuestionFilter<number>> => ({
  defaultValue: fallback,
  parser: (val) => parseQuestionFilter(val, (x) => parseNumberOrDefault(x, 0), true),
});

export const networkParser = (fallback: string): ValueParser<QuestionFilter<string>> => ({
  defaultValue: parseQuestionFilter(fallback),
  parser: parseQuestionFilter,
});

export const weightingSchemeParser = (fallback: string | undefined): ValueParser<QuestionFilter<string>> => ({
  defaultValue: fallback ? { kind: "applied", value: fallback } : { kind: "not-applied" },
  parser: parseQuestionFilter,
});

/**
 * Pattern match on a `QuestionFilter`'s `kind` property.
 * Allows you to construct different data depending on the type of filter.
 *
 * @example
 *
 * // determine a final weighting scheme based on a base config and override
 * weightingMode: matchQuestionFilter(globalFilters.weightingScheme, {
 *   'applied': x => x,
 *   'not-applied': () => config.weightingMode,
 *   'clear-current-value': () => undefined,
 * }),
 */
export const matchQuestionFilter = <Id extends string | number, TResult>(
  val: QuestionFilter<Id>,
  args: QuestionFilterMatch<Id, TResult>,
): TResult => {
  switch (val.kind) {
    case "applied":
      return args.applied(val.value);
    case "not-applied":
      return args["not-applied"]();
    case "clear-current-value":
      return args["clear-current-value"]();
  }
};

/**
 * Pattern matches against the provided `QuestionFilterDisplayOption` to invoke a more specific function per `kind`.
 */
export const matchDisplayOption = <T>(display: QuestionFilterDisplayOption, map: MatchDisplayOptionMap<T>): T => {
  switch (display.kind) {
    case "grouping":
      return map.grouping(display.groupingUri);
    case "score":
      return map.score(display.scoreUri);
    case "ungrouped":
      return map.ungrouped();
  }
};

/**
 * Returns an instance of `QuestionFilter` where all values are set to their "empty" values.
 */
export const emptyQuestionFilters = (): QuestionFilters => ({
  dateFilter: { kind: "all" },
  network: { kind: "not-applied" },
  segment: { kind: "not-applied" },
  target: { kind: "not-applied" },
  weightingScheme: { kind: "not-applied" },
});

/**
 * Helper to "override" a `baseFilter` based on the value of a `QuestionFilter`.
 * As an example, we use this on the dashboard to allow users to "override" dashlet config via global filters.
 */
export const overrideQuestionFilter = <T extends string | number>(
  overrideFilter: QuestionFilter<T>,
  baseFilter: T | null | undefined,
) =>
  matchQuestionFilter(overrideFilter, {
    applied: (x) => x,
    "not-applied": () => baseFilter,
    "clear-current-value": () => undefined,
  });

/**
 * Parses a string value into a `QuestionFilterDisplayOption`.
 */
export const parseDisplayOption = (val: string | null | undefined): QuestionFilterDisplayOption => {
  const [displayType, uri] = (val ?? "").split(":");
  if (displayType === "score" && !!uri) {
    return { kind: "score", scoreUri: uri };
  }

  if (displayType === "grouping" && !!uri) {
    return { kind: "grouping", groupingUri: uri };
  }

  return { kind: "ungrouped" };
};

/**
 * Encodes a display option as a single string.
 * We use this to represent the display option as a query string and select option value.
 */
export const encodeDisplayOption = (display: QuestionFilterDisplayOption): string =>
  matchDisplayOption(display, {
    grouping: (uri) => `grouping:${uri}`,
    score: (uri) => `score:${uri}`,
    ungrouped: () => "",
  });
