import {
  AllNullable,
  ApiDateFilter,
  DashletApiDateFilters,
  DateFilter,
  DateFilterSearch,
  DateRange,
  MultiTimeviewDateFilter,
  PresetRelativeDay,
  RelativeDateFilter,
  RelativeDateFilterUnit,
  ReportDateFilterType,
} from "types";
import { parseFromYmd, parseFromYmdNoDashes, parseNumberOrDefault, relativeDateToDateRange } from "utils";

import { DateFormats } from "consts";
import { ValueParser } from "utils/url/querystring";
import { format } from "date-fns";
import { isNullOrUndef } from "@civicscience/chops";
import qs from "qs";
import queryString from "utils/url/querystring";

export type CustomFixedDateFormValues = {
  minDate: Date | null;
  maxDate: Date | null;
};

export type DashletDateFilterFormValues = {
  dateFilter: DateFilter;
  customFixedDateFilter: CustomFixedDateFormValues;
  customRelativeDateFilter: CustomRelativeDateFormValues;
};

export type CustomRelativeDateFormValues = AllNullable<RelativeDateFilter>;

/**
 * Builds an "empty" `ApiDateFilter` with every field empty/off.
 * Useful as a base object to merge values.
 */
export const emptyApiDateFilter = (): ApiDateFilter => ({
  noDateFilter: null,
  daysBefore: null,
  relativeDateFilter: null,
  customDateFilter: null,
  customRelativeDateFilter: null,
  minDate: null,
  maxDate: null,
});

export const dayValuesToLabel: Readonly<Record<number, PresetRelativeDay>> = {
  1: "relative-days-1",
  7: "relative-days-7",
  30: "relative-days-30",
  90: "relative-days-90",
  180: "relative-days-180",
  365: "relative-days-365",
};

export const dayLabelToValues: Readonly<Record<PresetRelativeDay, number>> = {
  "relative-days-1": 1,
  "relative-days-7": 7,
  "relative-days-30": 30,
  "relative-days-90": 90,
  "relative-days-180": 180,
  "relative-days-365": 365,
};

export const dateFilterOptions: Readonly<{ value: DateFilter; text: string }[]> = [
  { value: "all", text: "All" },
  { value: "relative-days-1", text: "Today" },
  { value: "relative-days-7", text: "Last 7 Days" },
  { value: "relative-days-30", text: "Last 30 Days" },
  { value: "relative-days-90", text: "Last 90 Days" },
  { value: "relative-days-180", text: "Last 180 Days" },
  { value: "relative-days-365", text: "Last 365 Days" },
  { value: "custom-fixed", text: "Custom Fixed" },
  { value: "custom-relative", text: "Custom Relative" },
];

export const dateFilterPeriodOptions: Readonly<{ value: RelativeDateFilterUnit; text: string }[]> = [
  { value: "days", text: "Days" },
  { value: "weeks", text: "Weeks" },
  { value: "months", text: "Months" },
  { value: "quarters", text: "Quarters" },
  { value: "years", text: "Years" },
];

/**
 * Maps each period to a value so that they can be ranked/compared/ordered.
 */
export const dateFilterPeriodRanking: Readonly<Record<RelativeDateFilterUnit, number>> = {
  days: 0,
  weeks: 1,
  months: 2,
  quarters: 3,
  years: 4,
};

/**
 * Compares 2 date filter periods as you would when sorting.
 * Positive number indicates `x > y`, 0 indicates `x = y`, negative number indicates `x < y`.
 */
export const compareDateFilterPeriod = (x: RelativeDateFilterUnit, y: RelativeDateFilterUnit): number =>
  dateFilterPeriodRanking[x] - dateFilterPeriodRanking[y];

/**
 * Narrows a `string` to a `PresetRelativeDay`.
 */
const isPresetRelativeDay = (x: string): x is PresetRelativeDay => x.startsWith("relative-days-");

/**
 * Determines if the provided value is a fully populated `RelativeDateFilter`
 * since forms allow null/invalid values while editing. Zero/null is allowed
 * for relative value.
 */
export const isRelativeFilter = (filter: CustomRelativeDateFormValues | null): filter is RelativeDateFilter =>
  !!(filter && filter.periodUnit && filter.periodValue && filter.relativeUnit);

export const relativeDateRangeOrNull = (filter: CustomRelativeDateFormValues | null): DateRange | null => {
  return isRelativeFilter(filter) ? relativeDateToDateRange(filter) : null;
};

export const isValidRelativeUnit = (
  period: RelativeDateFilterUnit | null,
  ago: RelativeDateFilterUnit | null,
): boolean => {
  return period && ago ? compareDateFilterPeriod(ago, period) >= 0 : false;
};

/**
 * Determines the correct `DateFilter` for a `QuestionResultsDashlet`.
 */
export const getDateFilter = (filters: DashletApiDateFilters): DateFilter => {
  if (filters.customRelativeDateFilter?.relativeUnit) {
    return "custom-relative";
  }

  if (filters.customDateFilter) {
    return "custom-fixed";
  }

  return dayValuesToLabel[filters.daysBefore ?? -1] ?? "all";
};

/**
 * Maps the DateFilter component form shape to the shape used by the API for date filters.
 * This is the inverse to `mapFormDashletToDates`.
 */
export const mapFormDatesToApiDates = (data: DashletDateFilterFormValues): DashletApiDateFilters => {
  if (data.dateFilter === "custom-relative") {
    const { periodValue, periodUnit, relativeValue, relativeUnit } = data.customRelativeDateFilter;
    return {
      ...emptyApiDateFilter(),
      customRelativeDateFilter: {
        periodValue: periodValue ?? 0,
        periodUnit: periodUnit ?? "days",
        relativeValue: relativeValue ?? 0,
        relativeUnit: relativeUnit ?? "days",
      },
    };
  }

  if (data.dateFilter === "custom-fixed") {
    const { minDate, maxDate } = data.customFixedDateFilter;
    return {
      ...emptyApiDateFilter(),
      customDateFilter: true,
      minDate: minDate ? format(minDate, DateFormats.Y_M_D) : null,
      maxDate: maxDate ? format(maxDate, DateFormats.Y_M_D) : null,
    };
  }

  if (isPresetRelativeDay(data.dateFilter)) {
    return {
      ...emptyApiDateFilter(),
      daysBefore: dayLabelToValues[data.dateFilter],
      relativeDateFilter: true,
    };
  }

  return {
    ...emptyApiDateFilter(),
    noDateFilter: true,
  };
};

/**
 * Maps the date filter shape coming from the API to the shape used by the DateFilter component.
 * This is the inverse to `mapFormDatesToDashlet`.
 */
export const mapApiDatesToFormDates = (data: ApiDateFilter | null | undefined): DashletDateFilterFormValues => {
  if (!data) {
    return {
      dateFilter: "all",
      customFixedDateFilter: { minDate: null, maxDate: null },
      customRelativeDateFilter: { periodValue: null, periodUnit: "days", relativeValue: null, relativeUnit: "days" },
    };
  }
  return {
    dateFilter: getDateFilter(data),
    customFixedDateFilter: {
      minDate: parseFromYmd(data.minDate),
      maxDate: parseFromYmd(data.maxDate),
    },
    customRelativeDateFilter: {
      periodValue: data.customRelativeDateFilter?.periodValue ?? null,
      periodUnit: data.customRelativeDateFilter?.periodUnit ?? "days",
      relativeValue: data.customRelativeDateFilter?.relativeValue ?? null,
      relativeUnit: data.customRelativeDateFilter?.relativeUnit ?? "days",
    },
  };
};

/**
 * Transforms a `DateFilterSearch` instance to a `ApiDateFilter` instance.
 * Useful for translating question filters into the shape needed for a Dashlet's date config.
 */
export const mapDateFilterSearchToApiDates = (dates: DateFilterSearch): ApiDateFilter => {
  switch (dates.kind) {
    case "all":
    case "live":
      return { ...emptyApiDateFilter(), noDateFilter: true };
    case "custom-fixed":
      return {
        ...emptyApiDateFilter(),
        customDateFilter: true,
        minDate: dates.startDate ? format(dates.startDate, DateFormats.Y_M_D) : null,
        maxDate: dates.endDate ? format(dates.endDate, DateFormats.Y_M_D) : null,
      };
    case "custom-relative":
      return {
        ...emptyApiDateFilter(),
        customRelativeDateFilter: {
          periodUnit: dates.periodUnit,
          periodValue: dates.periodValue,
          relativeUnit: dates.relativeUnit,
          relativeValue: dates.relativeValue,
        },
      };
  }
};

export type ReportDateFilterFormValues = {
  dateFilter: ReportDateFilterType;
  customFixedDateFilter: CustomFixedDateFormValues;
  customRelativeDateFilter: CustomRelativeDateFormValues;
};

// Report Date Filters
export const reportDayValuesToLabel: Readonly<Record<string, Exclude<PresetRelativeDay, "relative-days-1">>> = {
  week: "relative-days-7",
  month: "relative-days-30",
  "3months": "relative-days-90",
  "6months": "relative-days-180",
  year: "relative-days-365",
};

export const reportDayLabelToValues: Readonly<Record<Exclude<PresetRelativeDay, "relative-days-1">, string>> = {
  "relative-days-7": "week",
  "relative-days-30": "month",
  "relative-days-90": "3months",
  "relative-days-180": "6months",
  "relative-days-365": "year",
};

export const reportDayValuesToGroupLabel: Readonly<Record<string, string>> = {
  week: "in the last week",
  month: "in the last month",
  "3months": "in the last  3 months",
  "6months": "in the last 6 months",
  year: "in the last year",
};

export const parseReportApiCustomFixedDate = (dateRange: string): Record<string, string> => {
  const dateArr = dateRange.split(":");
  const minDate = dateArr[1];
  const maxDate = dateArr[2];
  return { minDate, maxDate };
};

export const getReportDateFilter = (dateRange: MultiTimeviewDateFilter): ReportDateFilterType => {
  if (typeof dateRange === "string") {
    if (dateRange.includes(":")) return "custom-fixed";
    else return reportDayValuesToLabel[dateRange ?? -1] ?? "all";
  } else if (dateRange?.periodValue) {
    return "custom-relative";
  }
  return "all";
};
/**
 * Maps the date filter shape coming from the API to the shape used by the DateFilter component.
 * This is the inverse to `mapFormDatesToDashlet`.
 */
export const mapReportApiDatesToFormDates = (dateRange: MultiTimeviewDateFilter): ReportDateFilterFormValues => {
  const emptyFixedDateFilter = { minDate: null, maxDate: null };

  const emptyCustomRelativeDateFilter: AllNullable<RelativeDateFilter> = {
    periodValue: null,
    periodUnit: "days",
    relativeValue: null,
    relativeUnit: "days",
  };
  const dateFilter = getReportDateFilter(dateRange);
  if (dateFilter === "custom-fixed") {
    const { minDate, maxDate } = parseReportApiCustomFixedDate(dateRange as string);
    return {
      dateFilter,
      customFixedDateFilter: {
        minDate: parseFromYmdNoDashes(minDate),
        maxDate: parseFromYmdNoDashes(maxDate),
      },
      customRelativeDateFilter: emptyCustomRelativeDateFilter,
    };
  } else if (dateFilter === "custom-relative") {
    const date = dateRange as RelativeDateFilter;
    return {
      dateFilter,
      customRelativeDateFilter: {
        periodValue: date.periodValue ?? null,
        periodUnit: date.periodUnit ?? "days",
        relativeValue: date.relativeValue ?? null,
        relativeUnit: date.relativeUnit ?? "days",
      },
      customFixedDateFilter: emptyFixedDateFilter,
    };
  }
  return {
    dateFilter,
    customFixedDateFilter: emptyFixedDateFilter,
    customRelativeDateFilter: emptyCustomRelativeDateFilter,
  };
};

export const mapReportFormDatesToApiDates = (data: ReportDateFilterFormValues): RelativeDateFilter | string | null => {
  if (data.dateFilter === "custom-relative") {
    return data.customRelativeDateFilter as RelativeDateFilter;
  }
  if (data.dateFilter === "custom-fixed") {
    const { minDate, maxDate } = data.customFixedDateFilter;
    const minDateFormat = isNullOrUndef(minDate) ? "" : format(minDate, DateFormats.Y_M_D_NO_DASH);
    const maxDateFormat = isNullOrUndef(maxDate) ? "" : format(maxDate, DateFormats.Y_M_D_NO_DASH);
    return "DateRange:".concat(minDateFormat).concat(":").concat(maxDateFormat);
  }
  if (isPresetRelativeDay(data.dateFilter)) {
    return reportDayLabelToValues[data.dateFilter];
  }
  return null;
};

export const reportCustomFixedDateRangeOrNull = (filter: string): DateRange | null => {
  try {
    const { minDate, maxDate } = parseReportApiCustomFixedDate(filter);
    const startDate: Date | null = parseFromYmdNoDashes(minDate);
    const endDate: Date | null = parseFromYmdNoDashes(maxDate);
    return { startDate, endDate };
  } catch (_e) {
    return null;
  }
};

export const reportFixedDateStringOrNull = (filter: string): string | null => {
  return reportDayValuesToGroupLabel[filter] || null;
};

/**
 * Parses a string representation of a DateFilterSearch into a DateFilterSearch instance.
 * Useful for reading an instance back in from the query string.
 * If an instance cannot be determined/parsed then the fallback will be returned.
 */
export const parseDateFilterSearch = (
  dateString: string | null | undefined,
  fallback: DateFilterSearch = { kind: "all" },
): DateFilterSearch => {
  if (!dateString) {
    return fallback;
  }

  const parsed = qs.parse(dateString);

  if (parsed.kind === "all" || parsed.kind === "live") {
    return { kind: parsed.kind };
  }

  if (parsed.kind === "custom-fixed") {
    const startDate = typeof parsed.startDate === "string" ? queryString.parseDate(parsed.startDate) : null;
    const endDate = typeof parsed.endDate === "string" ? queryString.parseDate(parsed.endDate) : null;
    return startDate || endDate ? { kind: "custom-fixed", startDate, endDate } : fallback;
  }

  if (parsed.kind === "custom-relative") {
    return {
      kind: "custom-relative",
      periodValue: parseNumberOrDefault(parsed.periodValue, 1),
      periodUnit: (parsed.periodUnit as RelativeDateFilterUnit) ?? "days",
      relativeValue: parseNumberOrDefault(parsed.relativeValue, 1),
      relativeUnit: (parsed.relativeUnit as RelativeDateFilterUnit) ?? "days",
    };
  }

  return fallback;
};

/**
 * Builds a parser for extracting a DateFilterSearch from the query string.
 * Accepts a default to be used when no query param is found.
 */
export const dateFilterSearchParserWithDefault = (
  defaultValue: DateFilterSearch = { kind: "all" },
): ValueParser<DateFilterSearch> => ({
  defaultValue,
  parser: parseDateFilterSearch,
});
