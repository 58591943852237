import { CSProvider, useCSContext } from "context/useCSContext";
import { useQuestion, useQuestionAnswerChoices } from "queries/questionQueries";

import { Context } from "context/question/QuestionContext";
import React from "react";
import { usePageFeedback } from "utils";

type QuestionProviderProps = {
  id: number;
  children: React.ReactNode;
};

/**
 * QuestionProvider wraps a chunk of code to provide the state of
 * the current question.
 */
export const QuestionProvider = ({ id, children }: QuestionProviderProps) => {
  const feedback = usePageFeedback();

  const { data: currentQuestion } = useQuestion(id, { onError: feedback.handleErrorWithMessage });
  const { data: currentAnswerChoices } = useQuestionAnswerChoices(id, { onError: feedback.handleErrorWithMessage });

  return (
    <CSProvider
      Context={Context}
      args={{ currentQuestion, currentAnswerChoices }}
      requiredKeys={["currentQuestion", "currentAnswerChoices"]}
      feedback={feedback}
    >
      {children}
    </CSProvider>
  );
};

/**
 * Functionally provides the currently selected Question
 */
export const useQuestionState = () => {
  const contextShape = useCSContext({
    Context,
    noProviderMsg: "Question Context used without Question Provider.",
  });

  return {
    ...contextShape,
    currentQuestionId: contextShape.currentQuestion.questionId,
  };
};
