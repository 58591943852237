import {
  QuestionFilterDisplayOption,
  QuestionFilterIds,
  QuestionListFormValues,
  QuestionListTableFilterValues,
} from "types";
import { encodeDisplayOption, querystring as qs } from "utils";

import { generatePath } from "react-router-dom";
import { routePaths } from "routing";

type QuestionDetailQueryArgs = Partial<QuestionFilterIds> & {
  displayOption?: QuestionFilterDisplayOption;
  tvDateFilter?: string;
};

/**
 * Args for any question detail route.
 * Includes the question ID as well as filters and display option (soon).
 */
export type QuestionDetailUrlArgs = QuestionDetailQueryArgs & {
  questionId: number | string;
  tvDateFilter?: string;
};

/**
 * Args to route to a tab on the question detail page.
 */
type QuestionDetailTabArgs = QuestionDetailUrlArgs & {
  tabPath: string;
  subTabPath?: string;
};

/**
 * Args to route to the Question Compare page.
 */
type GetQuestionCompareUrlArgs = QuestionDetailUrlArgs & {
  compareId: number | string;
};

export const QuestionRoutePaths = {
  Results: "results",
  Settings: "settings",
};

// TODO: If we go this route - remove duplicated code in QuestionResults.jsx
export const QuestionResultsSubRoutePaths = {
  TimeView: "time-view",
  CompareResult: "compare/:compareId",
  Compare: "compare",
  Insights: "insights",
  Profile: "profile",
  Segments: "segments",
  Targets: "targets",
  Geography: "geography",
};

/**
 * Builds the query string for a Question Detail page based on the provided args.
 */
const getQuestionDetailQueryArgs = (args: QuestionDetailQueryArgs): string =>
  qs.buildSearchString({
    qSegment: args.segmentUUID,
    qTarget: args.targetId,
    qNetwork: args.network,
    qWscheme: args.weightingSchemeName,
    qDateFilter: args.dateFilter,
    qDisplay: encodeDisplayOption(args.displayOption ?? { kind: "ungrouped" }),
    tvDateFilter: args.tvDateFilter,
  });

const getQuestionDetailSubTabUrl = ({ tabPath, subTabPath, questionId, ...filters }: QuestionDetailTabArgs): string =>
  [generatePath(routePaths.QUESTION, { id: questionId }), tabPath, subTabPath].filter(Boolean).join("/") +
  getQuestionDetailQueryArgs(filters);

/**
 * Generates a url to the default question details page.
 */
export const getQuestionDetailUrl = (args: QuestionDetailUrlArgs) =>
  getQuestionDetailSubTabUrl({
    ...args,
    tabPath: QuestionRoutePaths.Results,
  });

/**
 * Generates a url to question insights for a particular question.
 *
 * This includes any applied question filters.
 */
export const getQuestionInsightsUrl = (args: QuestionDetailUrlArgs) =>
  getQuestionDetailSubTabUrl({
    ...args,
    tabPath: QuestionRoutePaths.Results,
    subTabPath: QuestionResultsSubRoutePaths.Insights,
  });

/**
 * Generates a link to Question Compare including optional filters.
 */
export const getQuestionCompareUrl = ({ compareId, ...args }: GetQuestionCompareUrlArgs): string =>
  getQuestionDetailSubTabUrl({
    ...args,
    tabPath: QuestionRoutePaths.Results,
    subTabPath: generatePath(QuestionResultsSubRoutePaths.CompareResult, { compareId }),
  });

/**
 * Generates a link to Question Compare including optional filters.
 */
export const getQuestionTimeviewUrl = (args: QuestionDetailUrlArgs): string =>
  getQuestionDetailSubTabUrl({
    ...args,
    tabPath: QuestionRoutePaths.Results,
    subTabPath: generatePath(QuestionResultsSubRoutePaths.TimeView),
  });

/**
 * Generates a path to the Questions search page including any specified search params.
 * Note that the question search page will still apply defaults for any missing params.
 */
export const getQuestionsUrl = (params: Partial<QuestionListFormValues | QuestionListTableFilterValues> = {}) =>
  routePaths.QUESTIONS + qs.buildSearchString(params);

/**
 * Generates a path to the checkbox group detail page.
 */
export const getCheckboxGroupDetailUrl = (params: QuestionDetailUrlArgs): string =>
  generatePath(routePaths.CHECKBOX_QUESTION, { id: params.questionId }) + getQuestionDetailQueryArgs(params);
