import React, { useContext } from "react";

import { MultiTimeviewContext } from "context/multi-timeview/MultiTimeviewContext";
import { isNullOrUndefOrEmpty } from "@civicscience/chops";
import { useMultiTimeview } from "queries";

type MultiTimeviewProviderProps = {
  uuid: string;
  children: React.ReactNode;
};

/**
 * MultiTimeviewProvider provides the currently selected MultiTimeview
 */
export const MultiTimeviewProvider = ({ uuid, children }: MultiTimeviewProviderProps) => {
  const {
    data: currentMultiTimeview,
    isFetching: multiTimeviewIsFetching,
    error: multiTimeviewError,
  } = useMultiTimeview(uuid, {
    enabled: !isNullOrUndefOrEmpty(uuid),
  });

  const multiTimeviewIsLoaded = !!currentMultiTimeview;

  return (
    <MultiTimeviewContext.Provider
      value={{
        currentMultiTimeviewUUID: uuid,
        currentMultiTimeview,
        multiTimeviewIsLoaded,
        multiTimeviewIsFetching,
        multiTimeviewError,
      }}
    >
      {children}
    </MultiTimeviewContext.Provider>
  );
};

/**
 * Functionally provides the currently selected Multi Timeview
 */
export const useMultiTimeviewState = () => {
  const {
    currentMultiTimeviewUUID,
    currentMultiTimeview,
    multiTimeviewIsFetching,
    multiTimeviewIsLoaded,
    multiTimeviewError,
  } = useContext(MultiTimeviewContext);

  return {
    currentMultiTimeviewUUID,
    currentMultiTimeview,
    multiTimeviewIsFetching,
    multiTimeviewIsLoaded,
    multiTimeviewError,
  };
};
