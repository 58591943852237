import React, { useEffect, useRef } from "react";

import CSDropdownMenu from "components/shared/dropdown/CSDropdownMenu";
import { Dropdown } from "react-bootstrap";
import { ProfileAPI } from "api";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useAuthState } from "context/auth";
import { useGetUserAccounts } from "queries";
import { useIsMountedRef } from "@civicscience/chops";

/**
 * This components renders a view of the accounts(organizations)
 * the current user is a member of.
 */
const OrganizationView = ({ className = "", menuClassName = "" }) => {
  const { currentAccount, onSetCurrentAccount } = useAuthState();
  const { data: accounts = [currentAccount] } = useGetUserAccounts();

  const isMountedRef = useIsMountedRef(useRef, useEffect);

  const hasMultipleAccounts = accounts.length > 1;
  const displayAsTitle = !hasMultipleAccounts;
  const displayAsDropdown = hasMultipleAccounts;

  /**
   * Switch the current user's account
   *
   * @param {number} accountRefid
   */
  const onSwitchAccount = async (accountRefid) => {
    await ProfileAPI.switchAccount(accountRefid).then((response) => {
      isMountedRef.current && onSetCurrentAccount(response);
    });
  };

  return (
    <div className={classNames("csa-organization-view", className)}>
      {displayAsTitle && <span className="csa-organization-name">{currentAccount?.name}</span>}
      {displayAsDropdown && (
        <Dropdown onSelect={onSwitchAccount}>
          <Dropdown.Toggle variant="transparent" className="rounded-0 border-0 shadow-none fw-bold text-muted">
            {currentAccount?.name}
          </Dropdown.Toggle>
          <CSDropdownMenu className={classNames("border-gray-800 bg-gray-800", menuClassName)}>
            {accounts.map((account) => {
              const { name, accountId } = account;
              const { accountId: currentAccountId } = currentAccount;
              const isActive = currentAccountId === accountId;
              return (
                <Dropdown.Item
                  key={`${name}-${accountId}-dropdown-key`}
                  eventKey={accountId} // value passed to onSelect
                  active={isActive}
                >
                  {name}
                </Dropdown.Item>
              );
            })}
          </CSDropdownMenu>
        </Dropdown>
      )}
    </div>
  );
};

OrganizationView.propTypes /* remove-proptypes */ = {
  className: PropTypes.string,
  menuClassName: PropTypes.string,
};

export default OrganizationView;
