import { QueryParser, QueryRecord } from "utils/url/querystring";
import { querystring as qs, useQueryString } from "utils";
import { useHistory, useLocation } from "react-router-dom";

type NavigateOptions = {
  /**
   * If true then **all** params your parser is configured to manage will be updated.
   * If false, then only the params you explicitly passed will be updated.
   */
  includeAllParserParams?: boolean;
};

/**
 * Provides easy integration of using the query string to store state and navigate.
 * Retrieves query params on render and provides a `navigate` function
 * which allows you to apply updated values back to the query string (which
 * will then be read in again on render).
 *
 * Can be used as the foundation for search pages where the search form is
 * persisted to the query string to maintain the current search.
 *
 * @example
 * const { queryParams, navigate } = useQueryStringNav(routePaths.mySearchRoute, {
 *   searchTerm: qs.stringParserWithDefault(''),
 *   isActive: qs.boolParserWithDefault(true),
 * });
 *
 * // when a submit of search happens, update the query with new data
 * const handleSearchSubmit = (formData) => navigate(formData);
 *
 * @param baseRoutePath The base path of the current page - used by the returned `navigate` function.
 * @param queryParser a Record whose keys map to query param names.
 * @param paramTransformer function which transforms params before they are serialized.
 * @returns an object with `queryParams` and a `navigate` function
 */
const useQueryStringNav = <T extends QueryParser>(
  baseRoutePath: string,
  queryParser: T,
  paramTransformer = qs.defaultParamTransformer,
) => {
  const history = useHistory();

  // The `queryParams` will contain the specific values defined by your `queryParser`.
  // This includes default values you've specified as part of your parsers.
  // Additionally, we also grab the entire query as an object via `allParams`.
  // We'll merge both of these along with the provided `newValues`.
  // This allows us to maintain all existing query values while we merge in the new values.
  const { queryParams } = useQueryString(queryParser);
  const { search } = useLocation();
  const allParams = Object.fromEntries(new URLSearchParams(search));

  return {
    queryParams,
    navigate: (newParams: QueryRecord, { includeAllParserParams = true }: NavigateOptions = {}) => {
      const newQuery = qs.buildQueryParams(
        {
          ...allParams,
          ...(includeAllParserParams ? queryParams : {}),
          ...newParams,
        },
        paramTransformer,
      );
      history.push(`${baseRoutePath}?${newQuery}`);
    },
  };
};

export default useQueryStringNav;
