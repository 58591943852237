import qs, { QueryParser } from "utils/url/querystring";
import { useLocation } from "react-router-dom";

/**
 * Provides ability to read values from the query string.
 * Use this hook when you only need to _read_ query param values.
 *
 * @see `useQueryStringNav` if your page also updates the query string.
 *
 * @example
 * const { queryParams } = useQueryString({
 *   searchTerm: qs.stringParserWithDefault(''),
 *   isActive: qs.boolParserWithDefault(true),
 * });
 *
 * @param queryParser a Record whose keys map to query param names.
 */
const useQueryString = <T extends QueryParser>(queryParser: T) => {
  const location = useLocation();
  const queryParams = qs.getUrlParams(queryParser, location.search);

  return {
    queryParams,
    location,
  };
};

export default useQueryString;
