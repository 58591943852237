import { Account, User } from "types";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Appcues: any;
  }
}

export const identifyAppcuesUser = (user: User): void => {
  if (user) {
    window.Appcues?.identify(user.userId, {
      dateRegistered: user.userInfo.dateRegistered, // Unix timestamp of user registration date
      userRole: user.primaryUserAccountRole, // Current user’s role or permissions
      email: user.userInfo.email, // Current user's email
      firstName: user.firstName, // current user's first name
      lastName: user.lastName, //current user's last name
      isInternal: user.isInternal, //is the current user internal?
      isImpersonated: user.isImpersonated, //is the current user being impersonated?
      isSysadmin: user.isSysAdmin, //is the current user a sysadmin?
    });
  }
};

export const identifyAppcuesGroup = (account: Account): void => {
  if (account) {
    window.Appcues?.group(account.accountId, {
      companyName: account.name, // Name of the company
      accountType: account.accountType,
    });
  }
};

export const identifyAppcues = (user: User, account: Account): void => {
  if (window.Appcues) {
    identifyAppcuesUser(user);
    identifyAppcuesGroup(account);
  }
};

export const trackAppcuesEvent = (eventName: string, url: string): void => {
  window.Appcues?.track(eventName, { url });
};

/**
 * Notifies Appcues that the URL has changed.
 *
 * https://docs.appcues.com/dev-api-data/javascript-api-developer#page-3
 */
export const trackAppcuesPage = (): void => {
  window.Appcues?.page();
};
