import { MultiTimeview } from "types";
import { createContext } from "react";

type MultiTimeviewContextProps = {
  currentMultiTimeviewUUID: string;
  currentMultiTimeview?: MultiTimeview | null;
  multiTimeviewIsFetching?: boolean;
  multiTimeviewIsLoaded?: boolean;
  multiTimeviewError?: unknown;
};

const emptyContext = {
  currentMultiTimeviewUUID: "",
  currentMultiTimeview: null,
  multiTimeviewIsLoaded: false,
  multiTimeviewError: null,
};

export const MultiTimeviewContext = createContext<MultiTimeviewContextProps>(emptyContext);
MultiTimeviewContext.displayName = "MultiTimeviewContext";
